/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function(){
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');


                //Attractions Homes for sale Background image
                var colDesktop6= $('.c-attractions__image--hfs').data('desktop'),
                colMobile6 = $('.c-attractions__image--hfs').data('mobile');
                if(viewportWidth < 480 ){
                    $('.c-attractions__image--hfs').css('background-image', 'url('+colMobile6+')');
                }else{
                    $('.c-attractions__image--hfs').css('background-image', 'url('+colDesktop6+')');
                }


                //Amenities Homes for sale Background image
                var colDesktop5= $('.c-amenities__image--hfs').data('desktop'),
                colMobile5 = $('.c-amenities__image--hfs').data('mobile');
                if(viewportWidth < 480 ){
                    $('.c-amenities__image--hfs').css('background-image', 'url('+colMobile5+')');
                }else{
                    $('.c-amenities__image--hfs').css('background-image', 'url('+colDesktop5+')');
                }


                //Amenities Landing Page Background image
                var colDesktop4 = $('.c-amenities__image--landing').data('desktop'),
                colMobile4 = $('.c-amenities__image--landing').data('mobile');
                if(viewportWidth < 480 ){
                    $('.c-amenities__image--landing').css('background-image', 'url('+colMobile4+')');
                }else{
                    $('.c-amenities__image--landing').css('background-image', 'url('+colDesktop4+')');
                }

                //Attractions Landing Page Background image
                var colDesktop3 = $('.c-attractions__image--landing').data('desktop'),
                colMobile3 = $('.c-attractions__image--landing').data('mobile');
                if(viewportWidth < 480 ){
                    $('.c-attractions__image--landing').css('background-image', 'url('+colMobile3+')');
                }else{
                    $('.c-attractions__image--landing').css('background-image', 'url('+colDesktop3+')');
                }

                //Amenities Background image
                var colDesktop2 = $('.c-amenities__image--left').data('desktop'),
                colMobile2 = $('.c-amenities__image--left').data('mobile');
                if(viewportWidth < 480 ){
                    $('.c-amenities__image--left').css('background-image', 'url('+colMobile2+')');
                }else{
                    $('.c-amenities__image--left').css('background-image', 'url('+colDesktop2+')');
                }
                //Attractions Background image
                var colDesktop = $('.c-attractions__image--right').data('desktop'),
                colMobile = $('.c-attractions__image--right').data('mobile');
                if(viewportWidth < 480 ){
                    $('.c-attractions__image--right').css('background-image', 'url('+colMobile+')');
                }else{
                    $('.c-attractions__image--right').css('background-image', 'url('+colDesktop+')');
                }
            },
            finalize: function() {

                //*Mobile Menu*//
                $( ".c-header__toggle" ).click(function(e) {
                    e.preventDefault();

                    $(".c-header__mobile").slideToggle();
                });
                // for the mobile animation

                $(".c-header__toggle").click(function(){
                    $(this).toggleClass("is-active");
                });

                ///Split footer menu in 2
                $(document).ready(function() {
                    var numitems =  $(".f-nav__list li").length;

                    $(".f-nav__list").css("column-count",numitems/4);
                });

                // JavaScript to be fired on all pages, after page specific JS is fired

                var viewportWidth = $(window).width(),
              headerTop = $('.c-home__navigation').offset().top + (0),
              headerHeight = $('.c-home__navigation').outerHeight(true),
              adminbarHeight = $('#wpadminbar').outerHeight(true);

          if (viewportWidth <= 768) {
            $(window).scroll(function(){
              var scrollY = $(this).scrollTop();

              // Sticky Menu
              if (scrollY >= headerTop) {
                $('.c-home__navigation').addClass('is-sticky');
              } else {
                $('.c-home__navigation').removeClass('is-sticky');
              }

            });
          }
            }
        },
        // Home page
        'home': {
            init: function() {



                // JavaScript to be fired on the home page

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
